import React, { useState } from 'react'
import Container from '../../../components/container/container.component'
import Layout from '../../../components/layout/layout.component'
import contentPageStyles from './content.page.module.scss'
import Video from '../../../components/video/video.component'
import { graphql, useStaticQuery } from 'gatsby'
import { useSize } from '../../../hooks/size.hook'

export default function ContentPage() {
  const [videosRef, setVideosRef] = useState([])
  const { videoSize } = useSize()

  const data = useStaticQuery(graphql`
    query {
      allNodeVideos(sort: { order: ASC, fields: field_weight }) {
        nodes {
          id
          title
          field_youtube_url
          field_weight
          body {
            value
            summary
          }
        }
      }
      allMenuItems(
        filter: {
          childrenMenuItems: { elemMatch: { title: { ne: null } } }
          url: { eq: "/esteban-vicente" }
        }
      ) {
        nodes {
          id
          title
          url
          weight
          childrenMenuItems {
            weight
            url
            title
          }
        }
      }
    }
  `)

  const handleLoadedVideos = (video, name, isPaused) => {
    setVideosRef(oldArray => [
      ...oldArray.filter(ele => ele.name !== name),
      { video, name, isPaused },
    ])
    //console.log('videosRef', videosRef)
  }
  const handleClick = name => {
    console.log(videosRef, name)
    videosRef.forEach(ele => {
      if (ele.name !== name) ele.video.pauseVideo()
    })
  }
  const matchYoutubeUrl = url => {
    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/

    if (url.match(p)) {
      return url.match(p)[1]
    }
    return false
  }

  return (
    <Layout
      title="Artwork"
      className={contentPageStyles.load}
      secondary
      menuData={data.allMenuItems.nodes[0]}
      showFooter
      colorHeader="#fcf6f3"
      goBack={true}
    >
      <Container oneColumn={false}>
        <div className={contentPageStyles.container}>
          <h1>Videos</h1>
          <ul>
            {data.allNodeVideos.nodes.map(ele => {
              return (
                matchYoutubeUrl(ele.field_youtube_url) && (
                  <li key={ele.id}>
                    {videoSize && (
                      <>
                        <Video
                          onPlayVideo={handleClick}
                          onLoadVideos={handleLoadedVideos}
                          autoPlay={false}
                          videoSrcURL={ele.field_youtube_url}
                          type="list"
                          videoTitle={ele.title}
                          width="100%"
                          height={ele.field_weight === 0 ? videoSize : 220}
                        />
                      </>
                    )}
                    {videoSize && ele.body && (
                      <>
                        <h2>{ele.body.summary}</h2>
                        <div
                          dangerouslySetInnerHTML={{ __html: ele.body.value }}
                        />
                      </>
                    )}
                  </li>
                )
              )
            })}
          </ul>
        </div>
      </Container>
    </Layout>
  )
}
