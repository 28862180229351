import React, { useRef, useState } from 'react'
import videoStyles from './video.module.scss'
import Play from './play.svg'
import YouTube from 'react-youtube'

const Video = ({
  videoSrcURL,
  videoTitle,
  onPlayVideo,
  onLoadVideos,
  type,
  ...props
}) => {
  const [isPaused, setIsPaused] = useState(true)
  const [video, setVideo] = useState(null)
  const textRef = useRef(null)

  const playPause = () => {
    const pos = video ? video.getPlayerState() : null

    video && video.playVideo() && video.setPlaybackQuality('small')

    if (pos === 1) {
      video.pauseVideo()
    }
  }

  const handlePlay = () => {
    setIsPaused(false)
    onPlayVideo(textRef.current.innerText)
  }
  const handlePause = () => {
    setIsPaused(true)
  }
  const _onReady = event => {
    // access to player in all event handlers via event.target
    const currentVideo = event.target
    currentVideo.pauseVideo()
    setVideo(currentVideo)
    loadVideos(currentVideo)
  }

  const loadVideos = _video => {
    onLoadVideos && onLoadVideos(_video, textRef.current.innerText, isPaused)
  }

  return (
    <div className={videoStyles.video}>
      <div
        className={videoStyles.wrapper}
        style={{
          width: props.width,
          height: props.height,
        }}
      >
        <YouTube
          onPlay={handlePlay}
          onPause={handlePause}
          videoId={videoSrcURL ? videoSrcURL.split('=')[1] : null}
          onReady={_onReady}
          opts={{
            width: props.width,
            height: props.height,
            playerVars: {
              allowFullScreen: 1,
              accelerometer: 1,
              rel: 0,
              controls: 1,
              autoplay: 0,
              showinfo: 0,
              iv_load_policy: 0,
              cc_load_policy: 1,
              modestbranding: 0,
              encrypted_media: 1,
              picture_in_picture: 1,
            },
          }}
        />
        {video && (
          <button
            className={`${videoStyles.btn} ${
              !isPaused ? videoStyles.hide : ''
            }`}
            onClick={playPause}
          >
            <Play className={videoStyles.play} />
          </button>
        )}
      </div>
      <h1 ref={textRef}>{videoTitle}</h1>
    </div>
  )
}
export default Video
